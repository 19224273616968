<template>
  <div
    :ref="`recommendation-block--${id}`"
    class="recommendation-block"
  >
    <component
      :is="titleTag"
      v-if="title.trim().length"
      class="recommendation-block__title"
    >
      {{ title }}
    </component>
    <div class="recommendation-block__list">
      <ArticleCardSmall
        v-for="(article, index) in recommendationArticles"
        :key="article.id"
        v-bind="useArticleProps(article)"
        :loading-views="getLoadingViews(article.id)"
        disable-breadcrumb-circular-link
        class="recommendation-block__list-item"
        @appear.once="onAppear(article)"
        @click-on-article-card="sendAnalyticsArticleClick(article, index + 1)"
        @clicked-on-comments="sendAnalyticsCommentEvent(article)"
        @clicked-on-category="
          (_link, categoryName) => sendAnalyticsCategoryClick(article, categoryName)
        "
        @click-on-restore-button="updateViewsCount(article.id)"
        @favorite-delete="
          (favorite) => {
            onFavoriteDelete(favorite, article, false);
            sendAnalyticsFavoriteDeleteEvent(article);
          }
        "
        @favorite-create-folder-and-add="
          (nameFolder) => {
            onFavoriteCreateFolderAndAdd(nameFolder, article, false);
            sendAnalyticsFavoriteAddEvent(article, nameFolder);
          }
        "
        @favorite-create-folder-and-transfer="
          (nameFolder, favorite) => {
            onFavoriteCreateFolderAndTransfer(nameFolder, favorite, article, false);
          }
        "
        @favorite-transfer-to-folder="
          (favorite, folder) => onFavoriteTransferToFolder(favorite, folder, article, update)
        "
        @favorite-add-to-folder="
          (folder) => {
            onFavoriteAddToFolder(folder, article, false);
            sendAnalyticsFavoriteAddEvent(article, nameFolder);
          }
        "
        @favorite-deferred="onFavoriteDeferred"
      />
    </div>
    <div
      v-if="addLinkButton"
      class="recommendation-block__btn-container"
    >
      <TheLinkUi
        :href="link"
        class="recommendation-block__btn"
        @click="sendAnalyticsShowMore"
      >
        Показать ещё
      </TheLinkUi>
    </div>
  </div>
</template>

<script lang="ts">
  import type { PropType } from 'vue';

  import ArticleCardSmall from '@/components/lh-ui/ArticleCard/ArticleCardSmall/ArticleCardSmall.vue';
  import { getFullUrl } from '~/utils';

  import { isHomePage, isArchivePage, isTopPage, isHealthMain } from '~/utils/router';
  import PercentageViewed from '~/utils/percentageViewed';
  import TheLinkUi from '@/components/lh-ui/TheLinkUi/TheLink.vue';
  import { useArticlesStore } from '~/store/articles';
  import { useIndexStore } from '~/store';
  import type { RouteRecordNameGeneric } from 'vue-router';
  import { usePageStore } from '~/store/pagesData';
  import { useArticleProps } from '~/composables/useArticleProps';
  import { useFavorite } from '@/composables/useFavoriteMethods';
  import { useUpdatingViewsCount } from '@/composables/useUpdatingViewsCount';

  type thresholdOptions = {
    [key: string]: boolean;
  };

  export default defineNuxtComponent({
    name: 'RecommendationBlock',

    components: {
      ArticleCardSmall,
      TheLinkUi,
    },

    setup() {
      const {
        onFavoriteDelete,
        onFavoriteCreateFolderAndAdd,
        onFavoriteCreateFolderAndTransfer,
        onFavoriteTransferToFolder,
        onFavoriteAddToFolder,
        onFavoriteDeferred,
      } = useFavorite();
      const { loadingViewsIds, updateViewsCount, getLoadingViews } = useUpdatingViewsCount();

      return {
        onFavoriteDelete,
        onFavoriteCreateFolderAndAdd,
        onFavoriteCreateFolderAndTransfer,
        onFavoriteTransferToFolder,
        onFavoriteAddToFolder,
        onFavoriteDeferred,
        loadingViewsIds,
        updateViewsCount,
        getLoadingViews,
      };
    },

    inject: {
      area: {
        default() {
          return () => '';
        },
      },
    },

    props: {
      id: {
        type: String as PropType<string>,
        required: true,
      },
      title: {
        type: String as PropType<string>,
        default: '',
      },
      items: {
        type: Array as PropType<IArticle[]>,
        required: true,
      },
      addLinkButton: {
        type: Boolean,
        default: false,
      },
      link: {
        type: String,
        default: null,
      },
    },

    data: () => ({
      articlesWithLoadedPixels: [] as number[],
      percentageContent: {
        instance: null as any,
        key: 'content',
        category: 'Прокрутка материала',
        thresholds: {
          25: false,
          50: false,
          75: false,
          100: false,
        } as thresholdOptions,
      },
    }),

    computed: {
      routeName(): RouteRecordNameGeneric {
        return useRoute().name;
      },

      titleTag(): string {
        // кнопка используется только в блоке/виджете "подборка статей с кнопкой", в котором заголовок h2
        return this.addLinkButton ? 'h2' : 'div';
      },

      user(): IUser | null {
        return useIndexStore().user ?? null;
      },
      userIntegerId(): number | null {
        return this.user?.integer_id ?? null;
      },

      recommendationArticles(): IArticle[] {
        const currentArticle = usePageStore().currentArticle;

        return this.items.filter((item) => {
          return item.id !== currentArticle?.id;
        });
      },

      getArchiveName(): string {
        const name = this.routeName;

        switch (true) {
          case isHomePage(name):
            return 'главная';
          case isArchivePage(name) && !isTopPage(name):
            return 'архивы';
          case isTopPage(name):
            return 'лучшее';
          default:
            return '';
        }
      },

      throttledScroll() {
        return useThrottleFn(this.onScroll, 100);
      },
    },

    async mounted() {
      await this.$nextTick();
      this.initPercentage();

      window.addEventListener('scroll', this.throttledScroll);

      const includes = this.items.map((item) => item.id);

      const articlesStore = useArticlesStore();

      await articlesStore.fetchCommentsCount(includes);
      articlesStore.fetchViewsCount(includes);
      articlesStore.fetchAndSetFavorites({
        post_ids: includes.join(),
        limit: includes.length,
      });
    },

    beforeUnmount() {
      window.removeEventListener('scroll', this.throttledScroll);
    },

    methods: {
      useArticleProps,
      sendAnalyticsShowMore() {
        this.$sendYandexMetrika({
          level1: 'Клик_Показать еще_Виджет с кнопкой',
          level4: getFullUrl(this.link),
          level6: this.title,
        });
      },

      initPercentage(): void {
        const trackedElement = this.$refs[`recommendation-block--${this.id}`] as HTMLElement;

        if (trackedElement) {
          this.percentageContent.instance = new PercentageViewed(trackedElement);
        }
      },

      onScroll(): void {
        const contentPercent = this.percentageContent.instance
          ? this.percentageContent.instance.viewed
          : 0;

        if (contentPercent) {
          this.percentageSendAnalytics(this.percentageContent.thresholds, contentPercent);
        }
      },

      percentageSendAnalytics(thresholds: thresholdOptions, newValue: any) {
        const viewedThresholds = Object.keys(thresholds).filter((threshold) => {
          const result = threshold <= newValue && !thresholds[threshold];
          return result;
        });

        const isHome = isHomePage(this.routeName);
        const event = this.addLinkButton
          ? 'Прокрутка_виджет с кнопкой'
          : 'Прокрутка_рекомендательный блок';

        viewedThresholds.forEach((threshold) => {
          thresholds[threshold] = true;

          !isHome &&
            this.$sendAnalyticsSnowPlow({
              event_name: event,
              par3: String(threshold.toString()),
            });
        });
      },

      onAppear(article: IArticle): void {
        const testPixel = article?.testPixel;
        const isNotLoadedPixel = !this.articlesWithLoadedPixels.includes(article.id);
        if (testPixel?.length && isNotLoadedPixel) {
          testPixel.forEach((url) =>
            $fetch(url, { credentials: 'include' }).catch((error) => {
              this.$logger(
                'Error in sending text pixels for ' + article + ' in RecommendationBlock.vue',
                error,
              );
            }),
          );
          this.articlesWithLoadedPixels.push(article.id);
        }
      },

      sendAnalyticsCommentEvent(article: IArticle) {
        const { url } = article;
        const element = this.addLinkButton ? 'виджет с кнопкой' : 'рекомендательный блок';

        this.$sendAnalyticsSnowPlow({
          event_name: 'Комментарии_переход к комментариям',
          par3: url,
        });

        this.$sendYandexMetrika({
          level1: 'Комментарии_переход к комментариям',
          level4: getFullUrl(url),
          level6: isHealthMain(this.routeName)
            ? 'Главная здоровье'
            : isHomePage(this.routeName)
              ? 'Главная'
              : element,
        });
      },

      sendAnalyticsFavoriteDeleteEvent(article: IArticle) {
        this.$sendAnalyticsSnowPlow({
          event_name: 'Избранное_удаление',
          par3: article.url,
          par4: this.getArchiveName || 'внутренние',
        });

        this.$sendYandexMetrika({
          level1: 'Избранное_удаление избранного',
          level4: getFullUrl(article.url),
          level6: this.title,
        });
      },

      sendAnalyticsFavoriteAddEvent(article: IArticle, folderName: string) {
        const element = this.addLinkButton ? 'виджет с кнопкой' : 'рекомендательный блок';

        this.$sendAnalyticsSnowPlow({
          event_name: 'Избранное_добавление избранного',
          par3: article.url,
          par4: this.getArchiveName || 'внутренние',
          par5: folderName,
        });

        this.$sendYandexMetrika({
          level1: 'Избранное_добавление избранного',
          level4: getFullUrl(article.url),
          level6: this.title,
          level8: element,
        });
      },

      sendAnalyticsCategoryClick({ url }: IArticle, categoryName: string) {
        this.$sendAnalyticsSnowPlow({
          event_name: 'Выбор рубрики',
          par3: url,
          par4: categoryName,
        });

        this.$sendYandexMetrika({
          level1: 'Выбор рубрики',
          level4: getFullUrl(url),
          level5: categoryName,
          level6: this.title,
        });
      },

      sendAnalyticsArticleClick({ url, teaserUrl }: IArticle, order: number) {
        const event = this.addLinkButton
          ? 'Выбор материала_Виджет с кнопкой'
          : 'Выбор материала_рекомендательный блок';

        this.$sendAnalyticsSnowPlow({
          event_name: event,
          par3: url,
          par5: String(order),
        });

        this.$sendYandexMetrika({
          level1: event,
          level4: getFullUrl(url),
          level5: order,
          level6: this.title,
          level8: teaserUrl ? 'Тизер' : 'Списочная карточка',
        });
      },
    },
  });
</script>

<style lang="scss" scoped>
  .recommendation-block {
    background-color: #fff;
    border: 1px solid #e7e7e7;

    .widget-between-posts {
      .recommendation-block {
        border-left: none;
        border-right: none;
      }
    }

    &__title {
      padding: 16px;

      @include fontH2;

      @include tablet {
        padding: 24px 24px 16px;
      }
    }

    &__list-item {
      // ::v-deep .lh-favorite-block {
      //   .lh-button-icon__text {
      //     display: none;
      //   }
      // }

      & + & {
        position: relative;

        &:before {
          content: '';
          left: 16px;
          right: 16px;
          top: 0;
          display: block;
          position: absolute;
          border-top: 1px solid #e7e7e7;

          @include tablet {
            left: 24px;
            right: 24px;
          }
        }
      }
    }

    &__btn-container {
      padding: 0 16px 24px;
      margin-top: 8px;

      @include tablet {
        padding: 0 24px 32px;
      }
    }

    &__btn {
      width: 100%;
      display: block;
      text-align: center;
      padding: 12px 0;
      background-color: var(--main-color);

      color: #fff;
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      font-family: $mainFont;
      text-decoration: none;

      @include hover {
        background-color: var(--hover-color);
      }
    }
  }
</style>
