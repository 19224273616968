<template>
  <div class="health-button-collection-container">
    <RecommendationBlock
      v-if="articles.length"
      :id="id"
      :items="getFormattingArticles(articles || [])"
      :title="title"
      :link="link"
      add-link-button
    />
  </div>
</template>

<script lang="ts">
  import type { PropType } from 'vue';
  import RecommendationBlock from '~/components/RecommendationBlock/RecommendationBlock.vue';
  import { formattingItemSection } from '~/utils/formattingData';
  import { trimWpDomainInPlace } from '~/utils/trimWpDomain';

  export default defineNuxtComponent({
    name: 'HealthButtonCollection',

    components: {
      RecommendationBlock,
    },

    props: {
      fieldType: {
        type: String as PropType<'category' | 'post_tag' | 'by_name'>,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
      category: {
        type: String as PropType<string>,
        default: '',
      },
      subcategory: {
        type: String as PropType<string>,
        default: '',
      },
      tag: {
        type: String,
        default: '',
      },
      nameSearch: {
        type: Array as PropType<Array<string>>,
        default: () => [''],
      },
      id: {
        type: String,
        required: true,
      },
      link: {
        type: String,
        default: null,
      },
    },

    async setup(props) {
      const app = useNuxtApp();

      async function fetchTag(tag: string) {
        const { data, error } = await useAsyncData(`fetch_tag_${tag}`, () => {
          return app.$healthApi.fetchByTag(tag, 'posts', { limit: 5 });
        });

        if (error.value) {
          console.error('Error in FetchTag in HealthPostsCollection:', error.value);
        }

        trimWpDomainInPlace(data.value, ['items.url', 'items.categories.url']);
        return data.value?.items ?? [];
      }

      async function fetchCategory(category: string) {
        const { data, error } = await useAsyncData(`fetch_category_${category}`, () => {
          return app.$healthApi.fetchCategory(category, 'posts', { limit: 5 });
        });

        if (error.value) {
          console.error('Error in FetchCategory in HealthPostsCollection:', error.value);
        }

        trimWpDomainInPlace(data, ['items.url', 'items.categories.url']);
        return data.value?.items ?? [];
      }

      async function fetchByName(names: Array<string>) {
        const { data } = await useAsyncData(`fetch_names_${names.join(',')}`, async () => {
          try {
            const articles = await Promise.all(
              names.map(async (name) => {
                const response = await this.$healthApi.fetchSinglePost(name);
                trimWpDomainInPlace(response.data, ['url', 'categories.url']);
                return response.data.item;
              }),
            );
            return articles;
          } catch (e) {
            console.error('Error in fetchByName in HealthButtonCollection fetch:', e);
            return [];
          }
        });

        return data.value;
      }

      const articles =
        props.fieldType === 'category'
          ? await fetchCategory(props.category)
          : props.fieldType === 'post_tag'
            ? await fetchTag(props.tag)
            : props.fieldType === 'by_name'
              ? await fetchByName(props.nameSearch)
              : [];

      return {
        articles,
        fetchTag,
      };
    },

    methods: {
      getFormattingArticles(articles: ISinglePostServerResponse[]): ISingleArticleServerResponse[] {
        return articles.map((article: ISinglePostServerResponse) => formattingItemSection(article));
      },
    },
  });
</script>

<style lang="scss" scoped></style>
